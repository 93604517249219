import React from 'react';
import { AnimatedProps, animated, useInView } from '@react-spring/web';

import {
	BoxProps,
	Box,
	Text,
	Row,
	Col,
	Image,
	TextProps,
} from '@ui/components';
import { styled } from '@ui/styles';
import { BulletDimond } from '@ui/icons';

import ContentWrapper from '@common-lib/components/Content/ContentWrapper';
import ContentBox from '@common-lib/components/Content/ContentBox';

const Root = styled(ContentWrapper)(({ theme }) => ({
	position: 'relative',
	background: 'linear-gradient(61.03deg, #FFFAFB 9.1%, #F4CBD3 100.49%)',
	[theme.breakpoints.down('sm')]: {
		background: '#FEE5E9',
	},
}));
const LeftItem: React.FC<
	AnimatedProps<
		Omit<
			React.DetailedHTMLProps<
				React.HTMLAttributes<HTMLDivElement>,
				HTMLDivElement
			>,
			'ref'
		>
	>
> = props => {
	const [ref, springs] = useInView(() => ({
		from: { opacity: 0, x: -130, y: 20 },
		to: {
			opacity: 1,
			y: 20,
			x: 20,
		},
		delay: 1000,
	}));

	return (
		<animated.div ref={ref} style={springs} {...props}>
			<Image
				src="/images/ky_item2.png"
				alt="ky item"
				height={242}
				width={88}
				priority
			/>
		</animated.div>
	);
};

const RightItem: React.FC<
	AnimatedProps<
		Omit<
			React.DetailedHTMLProps<
				React.HTMLAttributes<HTMLDivElement>,
				HTMLDivElement
			>,
			'ref'
		>
	>
> = props => {
	const [ref, springs] = useInView(() => ({
		from: { opacity: 0, x: 100, rotate: 21, width: ' max-content' },
		to: {
			opacity: 1,
			x: -60,
			width: ' max-content',
			rotate: 21,
		},
		delay: 1000,
	}));

	return (
		<animated.div ref={ref} style={springs} {...props}>
			<Image
				src="/images/ky_clinical_item.png"
				alt="K-Y clinical item"
				height={280}
				width={190}
				priority
			/>
		</animated.div>
	);
};

const Heading = styled(Text)(({ theme }) => ({
	fontSize: '5.625rem',
	lineHeight: '5.625rem',
	fontWeight: '700',
	fontFamily: theme.fontFamily.chalet,
	color: theme.palette.primary.contrastText,
}));
const SubHeading = styled(Text)(({ theme }) => ({
	fontSize: '3rem',
	lineHeight: '3rem',
	fontWeight: '700',
	fontFamily: theme.fontFamily.chalet,
	color: theme.palette.primary.contrastText,
	marginBottom: 26,
}));
const HeadingWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	'& svg': {
		width: 10,
		height: 7,
		top: 8,
		position: 'absolute',
		left: -20,
		right: 'auto',
	},
	// paddingLeft: 17,
	// marginLeft: 10,
	maxWidth: 420,
	marginBottom: theme.spacing(2),
	'&.mb-2': {
		marginBottom: theme.spacing(2),
	},
}));

const TextLine = styled(props => (
	<Text {...props} component="div" />
))<TextProps>(({ theme }) => ({
	fontFamily: theme.fontFamily.mulish,
	fontSize: '1rem',
	lineHeight: '1.5rem',
}));
const Wrapper = styled(ContentBox)(({ theme }) => ({
	position: 'relative',
	minHeight: 1174,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
}));
const TheRow = styled(Row)(({ theme }) => ({
	position: 'absolute',
	left: 0,
}));
const TheCol = styled(Col)(({ theme }) => ({
	minHeight: 1174,
	height: '100%',
	'&.bg-1': {
		background:
			'radial-gradient(85.04% 85.04% at 93.19% 95.3%, #89CAC6 0%, #82BEC8 43.23%, #62A3BF 100%)',
	},
	'&.bg-2': {
		background:
			'radial-gradient(121.11% 135.67% at 114.58% -10.22%, #C07B7B 0%, #F4CBD3 100%)',
	},
}));
const LeftContent = styled(Box)(({ theme }) => ({
	textAlign: 'right',
	paddingRight: 150,
	paddingTop: theme.spacing(4),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-end',
}));
const RightContent = styled(Box)(({ theme }) => ({
	textAlign: 'left',
	paddingLeft: 150,
	paddingTop: theme.spacing(4),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
}));

const DifferenceDesktop: React.FC<Omit<BoxProps, 'ref'>> = ({ ...rest }) => {
	return (
		<Root>
			<TheRow>
				<TheCol className="bg-1" xs={6}></TheCol>
				<TheCol className="bg-2" xs={6}></TheCol>
			</TheRow>
			<Wrapper {...rest}>
				<Row justifyContent="center">
					<Heading>Qual a diferença?</Heading>
				</Row>
				<Row>
					<Col xs={6}>
						<LeftContent>
							<LeftItem />
							<SubHeading pt={4.5}>
								Lubrificante
								<br />
								Íntimo
							</SubHeading>
							<HeadingWrapper>
								<TextLine>
									<BulletDimond htmlColor="black" />
									Produto para todos os públicos, indicado
									para reduzir atrito na região intima na
									relação sexual.
								</TextLine>
							</HeadingWrapper>
							<HeadingWrapper>
								<TextLine>
									<BulletDimond htmlColor="black" />
									Promove lubrificação da região íntima e é
									facilmente removível após o uso.
								</TextLine>
							</HeadingWrapper>
							<HeadingWrapper>
								<TextLine>
									<BulletDimond htmlColor="black" />
									Minimiza o desconforto do ressecamento
									durante as relações sexuais.
								</TextLine>
							</HeadingWrapper>
							<HeadingWrapper>
								<TextLine>
									<BulletDimond htmlColor="black" />
									Proporciona bem-estar e prazer nas relações
									sexuais.
								</TextLine>
							</HeadingWrapper>
						</LeftContent>
					</Col>
					<Col xs={6}>
						<RightContent>
							<RightItem />
							<SubHeading>
								Hidratante <br />
								Intravaginal
							</SubHeading>
							<HeadingWrapper>
								<TextLine>
									<BulletDimond htmlColor="black" />
									Produto indicado para o ressecamento
									vaginal, promove a hidratação por até 72h a
									cada aplicação,
								</TextLine>
							</HeadingWrapper>
							<HeadingWrapper>
								<TextLine>
									<BulletDimond htmlColor="black" />
									Mantém a umidade e lubrificação já no
									momento da primeiro aplicação e no uso
									contínuo,
								</TextLine>
							</HeadingWrapper>
							<HeadingWrapper>
								<TextLine>
									<BulletDimond htmlColor="black" />
									Minimiza o desconforto do ressecamento nas
									relações sexuais, além de outras
									consequências, como coceiras, pruridos,
									irritação,
								</TextLine>
							</HeadingWrapper>
							<HeadingWrapper>
								<TextLine>
									<BulletDimond htmlColor="black" />
									Proporciona conforto, bem-estar e prazer no
									dia a dia e nas relações sexuais.
								</TextLine>
							</HeadingWrapper>
						</RightContent>
					</Col>
				</Row>
			</Wrapper>
		</Root>
	);
};

export default DifferenceDesktop;
